import React from 'react'
//import Link from 'gatsby-link'
import HomeLayout from '../layout/home-layout'
import DbSections from '../components/db/db-sections'
import Info from '../components/contact-section'
import Summary from '../components/summary'

const Homepage = ({ pageContext }) => (
  <HomeLayout contextEntity={pageContext.page} cssClass="homepage">
    <Summary data={pageContext.page} />
      <div id="main-content">
        <DbSections>{pageContext.page.sections}</DbSections>
      </div>
    <Info />
  </HomeLayout>
)

export default Homepage
